<template>
  <div v-if="question">
    <div class="flex justify-between" v-if="question.name">
      <label class="block text-sm font-medium leading-5 text-gray-700">{{
        question.name
      }}</label>
      <span class="text-sm leading-5 text-gray-500" v-if="question.required"
        >Required</span
      >
    </div>
    <div class="flex items-center pt-3">
      <div class="flex-1 text-sm leading-5 text-gray-700 mr-2 lg:mr-5">
        {{ question.scaleFromLabel }}
      </div>
      <label
        class="flex-1 text-center text-sm mx-3 lg:mx-5"
        v-for="opt in options"
        :key="opt"
      >
        <input
          class="mr-2"
          type="radio"
          :name="question.id"
          :value="opt"
          v-model="option"
          :disabled="disabled"
        />
        <span>{{ opt }}</span>
      </label>
      <div
        class="flex-1 text-sm leading-5 text-gray-700 text-right ml-2 lg:ml-5"
      >
        {{ question.scaleUntilLabel }}
      </div>
    </div>
  </div>
</template>

<script>
import { range } from 'ramda';
export default {
  name: 'ScaleView',
  props: {
    question: { type: Object },
    answer: { type: Number },
    disabled: { type: Boolean },
    v: { type: Object }
  },
  computed: {
    options() {
      return range(this.question.scaleFrom, this.question.scaleUntil + 1);
    },
    option: {
      get() {
        return this.answer;
      },
      set(id) {
        this.$emit('change', id);
      }
    }
  }
};
</script>
